@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@700&family=Titillium+Web:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.custom-background-color {
  background-color: #030432 !important;
}

.container-box {
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container-box>.row {
  margin: 0px;
  flex: 1;
}

.container-box>.row .left-column {
  background-color: #030432;
}

.container-box>.row .left-column .left-logo {
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.container-box>.row .right-column {
  justify-content: center;
  display: flex;
}

.container-box>.row .right-column .container {
  margin: auto;
}

.container-box>.row .right-column .container .first-row {
  justify-content: center;
}

.container-box>.row .right-column .container .first-row .login-form-box {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}

.container-box>.row .right-column .container .first-row .form-group .label {
  font-weight: 700;
  font-size: 0.9em;
}

.container-box>.row .right-column .container .first-row .form-heading {
  color: #000;
  font-family: Inter;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 5px;
}

.container-box>.row .right-column .container .first-row .form-sub-heading {
  color: #000;
  font-family: Inter;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 5px;
}

.login-form-box .form-row {
  margin-bottom: 10px;
}

.login-form-box .form-group {
  margin-bottom: 10px;
}

.login-form-box .btn {
  margin-top: 10px;
}

.form-error {
  color: #ff5353;
  font-size: 0.8em;
  font-weight: 600;
}

/* ---------------------------------------------------
  TOP NAVIGATION
----------------------------------------------------- */
p {
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 10px 10px;
  border: none;
  border-radius: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
}

.navbar .btn {
  padding: 2px 12px;
  border: 1px rgb(238, 238, 238) solid;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.dropdown-toggle::after {
  display: none !important;
}

.right-top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-top-nav .cart-box {
  margin-right: 30px;
  position: relative;

  .cart-badge {
    left: 15px;
    position: absolute;
    opacity: .9;
  }

  .cart-badge:hover {
    opacity: 1;
  }
}

#top-nav-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#top-nav-dropdown[aria-expanded="true"] {
  background-color: transparent !important;
  font-weight: 600;
}

#top-nav-dropdown .image-box {
  border: solid 1px #a8a8a8;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 3px;

  img {
    width: auto;
    height: auto;
  }
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background-color: #030432;
  color: rgb(131, 131, 131);
  transition: all 0.3s;
  border-right: 1px solid #eee;
  padding: 10px;
}

#sidebar.active {
  width: 90px;
}

#sidebar.active .sidebar-header {
  justify-content: center;
  display: flex;
  padding: 10px;
}

#sidebar.active ul li {
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidebar .sidebar-header {
  font-size: 0.9em;
  padding: 10px;
  color: #fff;
  font-weight: 500;
  justify-content: center;
  display: flex;
}

#sidebar ul.components {
  height: 80%;
  overflow-y: auto;
  padding: 5px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0.226);
}

#sidebar ul li {
  margin-bottom: 4px;
}

#sidebar ul li a {
  font-size: 0.9em;
  font-weight: 500;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  align-items: center;
}

#sidebar.active ul li a {
  padding: 8px;
  width: 39px;
  height: 39px;
  justify-content: center;
}

#sidebar ul li a:hover {
  background: #FBFBFB;
  border-radius: 4px;
  color: #030432 !important;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  font-weight: 600;
  background: #eeeeee;
  border-radius: 4px;
  color: #030432 !important;
}

#sidebar ul li a>div {
  margin-left: 4px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.7em !important;
  padding-left: 30px !important;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  background-color: rgba(3, 4, 50, 0.03);
  ;
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 100px;
}

#content.active {
  width: calc(100% - 90px);
}

#content>.navbar-brand img {
  width: 35px;
  height: 35px;
}

#content>.navbar {
  background-color: #fff;
  border-bottom: 1px solid rgba(235, 235, 235, 0.514);
}

/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  #content.active {
    width: calc(100% - 90px);
  }

  #sidebarCollapse span {
    display: none;
  }
}

/* ---------------------------------
  GENERAL 
  ---------------------------------*/
.center-spinner {
  margin: auto;
}

.modal-spinner {
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.form-feedback-message {
  margin-bottom: 10px;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 0px !important;
  border-width: 0px 0px 0px 5px !important;
}

.form-instructions {
  font-size: 14px;
  color: #5009ac;
}

.form-heading {
  font-size: 1em;
  font-weight: 800;
}

.input-group-text {
  background: transparent !important;
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
}

.form-labels {
  font-size: 14px;
  color: #525252;
  font-weight: 400;
}

/* ---------------------------------
  DATEPICKER 
  ---------------------------------*/
.react-datepicker-wrapper {
  display: block !important;
}

/* ---------------------------------
 GENERAL DASHBOARD CSS
  ---------------------------------*/

.btn-custom {
  color: #FDFDFD !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  background: #0F1925 !important;
  border-color: #0F1925 !important;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1) !important;
}

.btn-custom:hover {
  color: #0F1925 !important;
  background: #FDFDFD !important;
}

.btn-custom-outline {
  color: #0F1925 !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  background: #FDFDFD !important;
  border-color: #0F1925 !important;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1) !important;
}

.btn-custom-outline:hover {
  color: #FDFDFD !important;
  background: #0F1925 !important;
}

.custom-text-input {
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  font-size: 0.9em !important;
  color: #5f5f5f !important;
}

.custom-text-input:focus {
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  outline: none !important;
  box-shadow: none !important;
}

.body-wrapper {
  padding: 20px;
}

.body-wrapper-heading {
  display: flex;
  justify-content: space-between;
}

.body-wrapper-heading-left .title {
  font-weight: bolder;
  font-size: 24px;
}

/* ---------------------------------
  SEARCH AREA COMPONENT
  ---------------------------------*/
.search-area {
  padding: 10px;
}

.search-area .search-bar {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
  background-color: #ffffff;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(9, 28, 77, 0.53);
}

.search-area .search-bar input {
  border: none;
}

.search-area .search-bar input:focus {
  outline: none;
  box-shadow: none !important;
}

.search-area .search-bar .input-group-text {
  background-color: transparent;
  border: none;
  color: rgba(9, 28, 77, 0.53);

}

.search-area .search-bar .cancel-button {
  background-color: transparent;
  box-shadow: none;
  color: #c9c9c9;
}

/* ---------------------------------
  TABLE COMPONENT
  ---------------------------------*/
.table {
  border-radius: 10px;
}

.table>thead {
  border-radius: 50px;
}

.table>thead>tr:last-child>th:first-child {
  /* background-color: #00146d; */
  border-top-left-radius: 3px;
}

.table>thead>tr:last-child>th:last-child {
  border-top-right-radius: 3px;
}

.table>thead>tr>th {
  background: #ffffff;
  color: rgb(119, 119, 119)444;
  font-size: 0.8em;
}

.table>tbody>tr>td {
  color: #525252;
  font-size: 0.8em;
}

.table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 3px;
}

.table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 3px;

}

/* ---------------------------------
  ADMIN DASHBOARD METRICS
  ---------------------------------*/
.admin-metric-row-1 .metric-box {
  display: flex;
  flex-direction: row;
  padding: 10px 18px;
  border-radius: 8px;
  background: #FBFBFB;
  align-items: center;
}

.admin-metric-row-1 .metric-box:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.admin-metric-row-1 .metric-box .left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  margin-right: 24px;
}

.admin-metric-row-1 .metric-box .right-side .metric-title {
  color: #525252;
  font-family: Inter;
  font-size: 1em;
  margin-bottom: 10px;
}

.admin-metric-row-1 .metric-box .right-side .metric-value {
  color: #0F1925;
  font-family: Inter;
  font-size: 1.5em;
  font-weight: 700;
}

/* ---------------------------------------------------
  Admin School Profile 
----------------------------------------------------- */
.profile-pic-section .image-outter-box {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic-section .image-outter-box .image-box {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
}

.profile-pic-section .image-outter-box .image-box img {
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  vertical-align: middle;
}

.profile-pic-section .image-outter-box .image-box img:hover {
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -moz-transform: scale(1.5);
  /* Firefox */
  -ms-transform: scale(1.5);
  /* IE 9 */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
}

.profile-pic-section .image-outter-box .dropdown {
  position: absolute;
  top: 80px;
  left: 80px;
}

.profile-pic-section .image-outter-box .dropdown .dropdown-toggle {
  background-color: #f5f5f5;
  color: #0F1925;
  padding: 1px 5px;
  border-color: transparent;
}

.profile-pic-section .image-outter-box .dropdown .dropdown-toggle::after {
  display: none !important;
}

/* ---------------------------------------------------
  PAGINATION 
----------------------------------------------------- */
.pagination-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  /* background: #F4F4F4; */
  padding: 5px 4px;
}

.pagination-row .left-side,
.pagination-row .right-side {
  display: flex;
}

.pagination-row .left-side .pagination {
  margin-bottom: 0px;
  align-items: center;
}

.pagination-row .right-side .pagination {
  margin-bottom: 0px;
  align-items: center;
}

.pagination-row .pagination select {
  padding: 3px 10px;
  background-color: transparent;
  border: none;
}

.pagination-row .pagination select:focus-visible {
  border: none;
  outline: none;
}

.pagination-row .pagination .page-item .page-link {
  padding: 3px 10px;
  background-color: transparent;
  color: #7c7c7c;
  border-width: 0px 1px;
  border-radius: 0px;
  border-color: #E0E0E0;
}

/* ---------------------------------
  MODAL
  ---------------------------------*/
.modal-content .modal-header {
  border-bottom: none;
}

.modal-content .modal-header .modal-title {
  font-size: 1em;
}

.modal-content .modal-footer {
  border-top: none;
  justify-content: space-between;
}

.modal-content .modal-footer button {
  width: 9em;
}

.freebies {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.freebies .btn {
  padding: 2px 5px !important;
}

/* ---------------------------------
  ERROR PAGE
  ---------------------------------*/
.error-container {
  display: flex;
  height: 100vh;
}

.error-container .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-container .error-code {
  font-size: 8em;
  text-align: center;
  font-family: fantasy;
}

.error-container .error-heading {
  text-align: center;
  font-size: 2em;
  font-weight: 800;
  font-family: serif;
}

.error-container .error-body {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.error-container .error-action {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* ---------------------------------
  ADMIN SCHOOL PROFILE
  ---------------------------------*/
.compartment {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1) !important;
}

.profile-links>a {
  padding: 3px;
  margin-right: 10px;
  text-decoration: none;
  font-size: small;
  font-weight: 700;
  color: #0F1925;
}

.profile-links>a:hover {
  text-decoration: underline;
}

/* ---------------------------------
  INVOICE
  ---------------------------------*/
.invoice-body {
  background: #FDFDFD;
  border-radius: 4px;
  overflow: hidden;
}

.invoice-body .invoice-heading {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  background-color: #0F1925;
  color: #fff;
}

.invoice-body .right-heading {
  color: #fff;
}

.invoice-body .right-heading img {
  max-width: 150px;
}

.invoice-body .invoice-row-two {
  padding: 5px 10px;
}

.invoice-body .invoice-row-two .item .title {
  color: #161616;
  font-weight: 600;
  font-size: 0.9em;
  margin-bottom: 3px;
}

.invoice-body .invoice-row-two .item .content {
  color: #525252;
  font-size: 0.9em;
}

.invoice-body .invoice-row-three {
  padding: 5px 10px;
}

.invoice-body .invoice-row-three .item .title {
  color: #161616;
  font-weight: 600;
  font-size: 0.9em;
  margin-bottom: 3px;
}

.invoice-body .invoice-row-three .item .content {
  color: #525252;
  font-size: 0.9em;
}

.invoice-body .invoice-row-four {
  padding: 5px 10px;
}

.invoice-body .invoice-row-four .item .title {
  color: #161616;
  font-weight: 600;
  font-size: 0.9em;
  margin-bottom: 3px;
}

.invoice-body .invoice-row-five .item .content {
  color: #525252;
  font-size: 0.9em;
}

.invoice-body .invoice-row-five {
  padding: 5px 10px;
}

.invoice-body .invoice-row-five .item .title {
  color: #161616;
  font-weight: 600;
  font-size: 0.9em;
  margin-bottom: 3px;
}

.invoice-body .invoice-row-five .item .content {
  color: #525252;
  font-size: 0.9em;
}

/* ---------------------------------
  SCHOOL LOGIN
  ---------------------------------*/
.school-login {
  background: url('./images/login-bg.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & .school-login-row {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .school-login-form-box {
    border-radius: 8px;
    background: #F5F5F5;
    padding: 0 !important;
    overflow: hidden;
  }
}

.school-login-row .school-login-form-box {
  border-radius: 8px;
  background: #F5F5F5;
  padding: 0 !important;
  overflow: hidden;
}

.school-login-row .school-login-form-box .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #030432;
  padding: 10px 5px;
}

.school-login-row .school-login-form-box .main-login-box {
  padding: 20px;
}

/* ----------------------------------------------------
  CARDS FOR PACKAGES AND SERVICES/MEMBERSHIP PLANS/RESOURCES
  ----------------------------------------------------*/
.price-card {
  border-radius: 8px !important;
  padding: 2%;
  border-color: #86868680;
  border-bottom: solid 8px #a90c45 !important;
}

.price-card:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.price-card .price-card-sub-title {
  font-weight: 600;
  color: #525252;
  font-size: small;
  text-align: center !important;
}

.price-card .price-card-title {
  font-weight: 900;
  font-size: large;
  text-align: center !important;
  color: #0F1925 !important;
}

.price-card .price-card-border {
  border-top: solid 1px #86868680;
}

.price-card .price-card-text {
  color: #0F1925;
  text-align: center !important;
}

.price-card .price-card-text>a {
  color: #301aaf;
  cursor: pointer;
}

.price-card .btn {
  align-self: center;
  background-color: #a90c45 !important;
  border: solid 1px #a90c45 !important;
}

.price-card .btn:hover {
  color: #a90c45 !important;
  background-color: #ffffff !important;
  border: solid 1px #a90c45 !important;
}

.price-card .view-link {
  align-self: center;
  text-align: center;
}

.price-card .view-link a {
  font-size: smaller;
  font-weight: 800;
  text-decoration: none !important;
}

.card-items-row :nth-child(2n) {
  .price-card {
    border-bottom: 8px solid #aa8302 !important;
  }

  .price-card .btn {
    background-color: #aa8302 !important;
    border: solid 1px #aa8302 !important;
  }

  .price-card .btn:hover {
    color: #aa8302 !important;
    background-color: #ffffff !important;
    border: solid 1px #aa8302 !important;
  }
}

.card-items-row :nth-child(3n) {
  .price-card {
    border-bottom: 8px solid #1d698c !important;
  }

  .price-card .btn {
    background-color: #1d698c !important;
    border: solid 1px #1d698c !important;
  }

  .price-card .btn:hover {
    color: #1d698c !important;
    background-color: #ffffff !important;
    border: solid 1px #1d698c !important;
  }
}

/* ----------------------------------------------------
  CARDS FOR SCHOOL BROADCASTS
  ----------------------------------------------------*/
.broadcast-card {
  border-radius: 8px !important;
  padding: 2%;
  border-color: #86868680;
  border-bottom: solid 8px #0F1925 !important;
}

.broadcast-card:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.broadcast-card .broadcast-card-sub-title {
  font-weight: 600;
  color: #525252;
  font-size: 1em;
}

.broadcast-card .broadcast-card-border {
  border-top: solid 1px #86868680;
}

.broadcast-card .broadcast-card-text {
  color: #0F1925;
}

.broadcast-card .broadcast-card-text>a {
  color: #301aaf;
  cursor: pointer;
}

.card-items-row :nth-child(2n) {
  .broadcast-card {
    border-bottom: 8px solid #aa8302 !important;
  }
}

.card-items-row :nth-child(3n) {
  .broadcast-card {
    border-bottom: 8px solid #1d698c !important;
  }
}

/* ----------------------------------------------------
  CARDS FOR SCHOOL DASHBOARD
  ----------------------------------------------------*/

.school-dashboard-user-box {
  display: flex !important;
  flex-direction: row !important;
  padding: 10px;
  border-radius: 8px;
  background-color: #030432 !important;

  .school-dashboard-image-box {
    width: 100px;
    height: 100px;
    overflow: hidden;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    border: 1px solid #f1f1f1;

    >img {
      max-width: 100px;
      max-height: 100px;
      -webkit-transition: all .2s ease;
      -moz-transition: all .2s ease;
      -ms-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
      vertical-align: middle;
    }

    >img:hover {
      -webkit-transform: scale(1.5);
      /* Safari and Chrome */
      -moz-transform: scale(1.5);
      /* Firefox */
      -ms-transform: scale(1.5);
      /* IE 9 */
      -o-transform: scale(1.5);
      /* Opera */
      transform: scale(1.5);
    }
  }

  .school-dashboard-user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    color: #fff;
    font-size: 0.9em;
  }
}

.school-dashboard-row-two {
  .school-dashboard-plan-box {
    padding: 10px;
    background-color: #f5eac8;

    .top {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      font-size: 0.9em;
      margin-bottom: 37px;

      .icon {
        border: 5px solid #aa8302;
        background-color: #aa8302;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        color: #fff !important;
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      font-size: 0.9em;

      .plan-name {
        font-size: .9em;
        font-weight: 800;
      }

      .plan-expiry {
        font-size: 0.8em;
      }
    }
  }

  .school-dashboard-membership-tracker-box {
    background-color: #f3cedc;

    .icon {
      border: 5px solid #ad134c;
      background-color: #a90c45;
    }
  }



  .school-dashboard-membership-tracker-box {
    padding: 10px;

    .top {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      font-size: 0.9em;

      .icon {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        color: #fff !important;
      }
    }

    .tracker-title {
      font-size: .8em;
    }

    .tracker-value {
      font-size: 0.9em;
      font-weight: 800;
    }
  }
}

.calendar-box {
  border-bottom: 1px solid #bebebe;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;

  .react-datepicker {
    margin: auto;
  }
}

.school-dashboard-card {
  /* border: none !important; */
  /* box-shadow: 1px 1px 7px #d6d6d683; */
  padding: 5px 10px;
  /* border: solid 1px #e6e6e6 !important; */

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .card-title {
      font-size: 1em;
      font-weight: 600;
      color: #303030;
    }

    .view-all {
      border-radius: 10px;
      font-size: 0.7em;
      padding: 2px 8px;
      font-weight: bolder;
      text-align: center;
      background-color: #c7c7c7;
      color: #424242;
      border: solid 1px #808080;
    }
  }

  .card-body {
    .table-responsive {
      height: 300px;
    }

    .table-responsive:hover {
      overflow: scroll;
      scrollbar-width: thin;
    }
  }
}


.dashboard-broadcasts-card {
  overflow: hidden;
  border: 1px solid #a90c45 !important;
}

.dashboard-broadcasts-card:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.dashboard-broadcasts-card .card-body {
  padding: 0px;
}

.dashboard-broadcasts-card .card-header {
  font-weight: 800;
  background-color: #fff;
}

.dashboard-broadcasts-card .card-footer {
  background-color: #fff;
  border: none;
  font-size: smaller;
}

.dashboard-broadcasts-card .card-title {
  font-size: small;
  font-weight: 500;
}

.dashboard-broadcasts-card .broadcast-item {
  padding: 10px;
  border-bottom: 1px solid #ececec !important;
}

.dashboard-broadcasts-card .broadcast-item:hover {
  background-color: #f1f1f1;
}

.dashboard-broadcasts-card .broadcast-item.unread {
  background-color: #dfdfdf7a;
}

.dashboard-broadcasts-card .broadcast-item.unread .card-title {
  color: #373aff;
  font-weight: 900;
}

.dashboard-menu-row .dashboard-menu-box {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  border: 1px solid #ececec !important;
}

.dashboard-menu-row .dashboard-menu-box:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.dashboard-menu-row .dashboard-menu-box .icon-block {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-items: center;
}

.dashboard-menu-row .dashboard-menu-box .icon-block>.icon {
  margin: auto;
}

.dashboard-menu-row .dashboard-menu-box .text-block {
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  color: #1a1a1ac5;
}

.right-side-dashboard-card {
  overflow: hidden;
  border: 1px solid #ececec !important;
}

/* ----------------------------------------------------
  CARDS FOR CHECKOUT
  ----------------------------------------------------*/
.checkout-card {
  border-radius: 8px !important;
  padding: 2%;
  border-color: #86868680;
  border-bottom: solid 8px #0F1925 !important;
}

.checkout-card:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.checkout-card .checkout-card-sub-title {
  font-weight: 600;
  color: #525252;
  font-size: 1em;
}

.checkout-card .checkout-card-border {
  border-top: solid 1px #86868680;
}

.checkout-card .checkout-card-text {
  color: #0F1925;
}

.checkout-card .checkout-card-text>a {
  color: #301aaf;
  cursor: pointer;
}

/* ----------------------------------------------------
  CARDS FOR CHECKOUT ITEMS
  ----------------------------------------------------*/

.checkout-items-card {
  border-radius: 8px !important;
  padding: 2%;
  border-color: #86868680;
}

.checkout-items-card:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
}

.checkout-items-card .checkout-items-card-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkout-items-card .checkout-items-card-sub-title {
  font-weight: 600;
  color: #525252;
  font-size: 1em;
}

.checkout-items-card .checkout-items-card-border {
  border-top: solid 1px #86868680;
}

.checkout-items-card .checkout-items-card-text {
  color: #0F1925;
}

.checkout-items-card .checkout-items-card-text>a {
  color: #301aaf;
  cursor: pointer;
}

/**
PDF VIEWER
*/
.pdf-view-box {
  display: flex;
  align-content: center;
  justify-content: center;
}

@media print {
  .pdf-container {
    display: none;
  }
}

/* ----------------------------------------------------
  CARDS FOR SCHOOL SERVICE PROVIDERS
  ----------------------------------------------------*/
.school-service-providers-box {
  .card-body {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-block {
      align-items: center;
      display: flex;
    }

    .text-block {
      margin-left: 3px;
      font-size: 0.9em;
      font-weight: 800;
    }
  }
}

/* ----------------------------------------------------
  BREADCRUMBS
  ----------------------------------------------------*/